import React from 'react';

function Skills() {
    return (
        <section className="skills">
            <h3>Fähigkeiten</h3>
            <div className="skills-grid">
                <div className="skill">
                    <h4>Active Directory</h4>
                    <p>Verwaltung und Implementierung von AD-Lösungen.</p>
                </div>
                <div className="skill">
                    <h4>Fileserver</h4>
                    <p>Konfiguration und Wartung von Dateiservern.</p>
                </div>
                <div className="skill">
                    <h4>Printserver</h4>
                    <p>Management von Druckdiensten und -ressourcen.</p>
                </div>
                <div className="skill">
                    <h4>Veeam</h4>
                    <p>Sicherungslösungen für virtualisierte Umgebungen.</p>
                </div>
                <div className="skill">
                    <h4>VMware & Hyper-V</h4>
                    <p>Virtualisierungsmanagement und -optimierung.</p>
                </div>
                <div className="skill">
                    <h4>IBM & HPE Storage</h4>
                    <p>Management von Speicherlösungen und Backup-Systemen.</p>
                </div>
                <div className="skill">
                    <h4>Linux/Unix</h4>
                    <p>Systemadministration und Skripterstellung.</p>
                </div>
                <div className="skill">
                    <h4>PowerShell</h4>
                    <p>Automatisierung von Aufgaben und Skripting.</p>
                </div>
		<div className="skill">
                    <h4>Active Directory</h4>
                    <p>Verwaltung und Implementierung von AD-Lösungen.</p>
                </div>
                <div className="skill">
                    <h4>Fileserver</h4>
                    <p>Konfiguration und Wartung von Dateiservern.</p>
                </div>
                <div className="skill">
                    <h4>Printserver</h4>
                    <p>Management von Druckdiensten und -ressourcen.</p>
                </div>
                <div className="skill">
                    <h4>Veeam</h4>
                    <p>Sicherungslösungen für virtualisierte Umgebungen.</p>
                </div>
		<div className="skill">
                    <h4>Active Directory</h4>
                    <p>Verwaltung und Implementierung von AD-Lösungen.</p>
                </div>
                <div className="skill">
                    <h4>Fileserver</h4>
                    <p>Konfiguration und Wartung von Dateiservern.</p>
                </div>
                <div className="skill">
                    <h4>Printserver</h4>
                    <p>Management von Druckdiensten und -ressourcen.</p>
                </div>
                <div className="skill">
                    <h4>Veeam</h4>
                    <p>Sicherungslösungen für virtualisierte Umgebungen.</p>
                </div>
		<div className="skill">
                    <h4>Active Directory</h4>
                    <p>Verwaltung und Implementierung von AD-Lösungen.</p>
                </div>
                <div className="skill">
                    <h4>Fileserver</h4>
                    <p>Konfiguration und Wartung von Dateiservern.</p>
                </div>
                <div className="skill">
                    <h4>Printserver</h4>
                    <p>Management von Druckdiensten und -ressourcen.</p>
                </div>
                <div className="skill">
                    <h4>Veeam</h4>
                    <p>Sicherungslösungen für virtualisierte Umgebungen.</p>
                </div>
            </div>
        </section>
    );
}

export default Skills;

