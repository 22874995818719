import React from 'react';

function Header() {
    return (
        <header className="header">
            <h1 className="header-title">Julian Schmitt - Systemadministrator</h1>
            <nav className="navbar">
                <ul>
                    <li>Startseite</li>
                    <li>Über mich</li>
                    <li>Fähigkeiten</li>
                    <li>Projekte</li>
                    <li>Kontakt</li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;

