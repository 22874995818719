import React from 'react';

function Contact() {
    return (
        <section className="contact">
            <h3>Kontakt</h3>
            <p>Wenn Sie mit mir in Kontakt treten möchten, erreichen Sie mich unter:</p>
            <p>Email: julian@beispiel.de</p>
        </section>
    );
}

export default Contact;

