import React from 'react';

function Hero() {
    return (
        <section className="hero">
            <h2>Willkommen in meinem Portfolio</h2>
            <p>Erfahrener Systemadministrator mit Fachkenntnissen in Active Directory, Veeam, VMware und mehr.</p>
        </section>
    );
}

export default Hero;

