import React from 'react';

function Projects() {
    return (
        <section className="projects">
            <h3>Projekte</h3>
            <div className="project">
                <h4>Projekt A</h4>
                <p>Beschreibung des Projekts A, Technologien, die verwendet wurden.</p>
            </div>
            <div className="project">
                <h4>Projekt B</h4>
                <p>Beschreibung des Projekts B, Technologien, die verwendet wurden.</p>
            </div>
		<div className="project">
                <h4>Projekt B</h4>
                <p>Beschreibung des Projekts B, Technologien, die verwendet wurden.</p>
            </div>
            <div className="project">
                <h4>Projekt B</h4>
                <p>Beschreibung des Projekts B, Technologien, die verwendet wurden.</p>
            </div>
            <div className="project">
                <h4>Projekt B</h4>
                <p>Beschreibung des Projekts B, Technologien, die verwendet wurden.</p>
            </div>
            <div className="project">
                <h4>Projekt B</h4>
                <p>Beschreibung des Projekts B, Technologien, die verwendet wurden.</p>
            </div>
        </section>
    );
}

export default Projects;

